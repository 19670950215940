// import React, { useState } from 'react'
// import bg2 from '../assets/bg2.jpg'
// import './Subscription.css';
// import logo from '../assets/logo_gameit.png'
// import axios from 'axios'
// import { Link, useNavigate } from 'react-router-dom'
// import { v4 as uuidv4 } from 'uuid';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';


// const Subscription = () => {
//   const url = '/subscription'
//   const [number, setNumber] = useState('')
//   const [language, setLanguage] = useState('en')

//   const [error, setError] = useState('')
//   const navigate = useNavigate()
//   const evinaRequestId = uuidv4();
//   const hostname = window.location.hostname;
//   // console.log("hostname",hostname)
//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {

//       const config = {
//         headers: {
//           'X-Hostname': hostname
//         }
//       };

//       // {"100":"Your request has been processed successfully"}

//       const response = await axios.post(url, { msisdn: number, requestId: evinaRequestId, lang: language }, config);
//       console.log("data", response.data)


//       const key = Object.keys(response.data.Response)
//       let firstKey = key[0];
//       let firstValue = response.data.Response[firstKey];
//       // console.log("value", firstValue)

//       if (key[0] == '3') {
//         toast.error(firstValue, {
//           position: toast.POSITION.TOP_RIGHT,
//         });


//       }

//       if (key[0] == '100') {
//         toast.success(firstValue, {
//           position: toast.POSITION.TOP_RIGHT,
//         });
//         navigate('/otp-validation', { state: { number, evinaRequestId } });
//       }
//       else {
//         navigate('/')
//         setNumber('')
//       }

//     } catch (error) {
//       console.error('Error making post request:', error);

//     }
//   };


//   const handleClick = () => {

//     window.location.href = "https://www.google.com/";
//   }


//   return (
//     <div className="bg h-[1200px] md:h-full" style={{ backgroundImage: `url(${bg2})`, height: '1200px' }}>

//       <div>


//         <nav class="bg-transparent border-gray-200 dark:bg-gray-900">
//           <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-1">
//             <a class="flex items-center space-x-3 rtl:space-x-reverse">
//               <img src={logo} class="h-10 w-30" alt="Flowbite Logo" />
//             </a>

//             <div class="   " id="navbar-default">
//               <button type="button" class="text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900 " onClick={() => setLanguage('en')}>EN</button>

//               <button type="button" class="text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900 " onClick={() => setLanguage('ar')}>AR</button>

//             </div>
//           </div>
//         </nav>

//       </div>


//       <p className='p-1 md:p-0 text-center text-white font-bold'>{language == 'en' ? "Free for 24 hours then AED 11/WEEKLY (VAT Inclusive)" : 'مجانًا لمدة 24 ساعة ! ثم ستُفرض على الخدمة 11 درهمًا إماراتياً  /أسبوعيًا  شاملاً ضريبة القيمة المضافة.'} </p>

//       <div className='md:py-[2px] py-[20px] px-5 md:px-0'>


//         <form onSubmit={handleSubmit} class="max-w-md h-[350px] md:h-[280px] mx-auto bg-transparent shadow-lg shadow-yellow-200  border  p-3 rounded-lg ">
//           <h1 className='text-white font-bold text-xl md:text-3xl text-center'>{language == 'en' ? "Gamevista" : "Gamevista"}</h1>
//           <p className='text-yellow-300 font-bold text-center'>{language == 'en' ? 'Exciting games for endless entertainment and fun !!' : "ألعاب مثيرة للترفيه والمتعة بلا حدود !!"}

//           </p>
//           <div class="mb-3">

//             <label for="number" class="block mb-2 text-center md:text-md text-sm font-bold text-gray-200 dark:text-white mt-2">{language == 'en' ? "Enter your Etisalat Mobile Number to receive OTP " : "أدخل رقم هاتفك المحمول من أتصالات للحصول على OTP"}</label>
//             <input
//               type="number"
//               id="number"
//               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-1.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 focus:outline-none"
//               placeholder={language == 'en' ? "Your number here" : "رقمك هنا"}
//               value={number}
//               onChange={(e) => setNumber(e.target.value)}
//               required
//             />
//           </div>
//           <div className='mx-auto flex flex-row  gap-3 justify-center'>
//             <button type="submit" class="text-white  bg-blue-700 hover:bg-blue-800   rounded-lg text-sm font-medium  sm:w-auto px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 ">{language == 'en' ? "Subscribe" : "اشترك"}</button>

//             <button type="button" onClick={handleClick} class="text-white bg-red-700 hover:bg-red-800 rounded-lg text-sm font-medium sm:w-auto px-5 py-2 text-center dark:bg-red-600 dark:hover:bg-red-700">
//               {language == 'en' ? "Exit" : "خروج"}
//             </button>
//           </div>



//           <p className='text-sm mt-1  text-white text-center'>{language == 'en' ? "Free for 24 hours then AED  11/WEEKLY (VAT Inclusive)" :"مجانًا لمدة 24 ساعة ! ثم ستُفرض على الخدمة 11 درهمًا إماراتياً  /أسبوعيًا  شاملاً ضريبة القيمة المضافة."}</p>

//           <p className='text-sm mt-1  text-white text-center'>{language == 'en' ? "After clicking 'Subscribe' you will receive PIN message to Confirm your subscription." : "بالضغط على 'اشترك'، سوف تتلقى رسالة تحتوي علي رمز PIN لتأكيد اشتراكك."} </p>

//         </form>


//         <div className='mt-3 md:font-bold   md:text-xl flex flex-col justify-center'>
//           {/* <span className='flex justify-center text-yellow-300'>{language == 'en' ?"Free for 24 hours then 11AED/Week":"مجانًا لمدة 24 ساعة ثم 11 درهمًا إماراتيًا/أسبوعًا"}</span> */}


//           {/* <span className='flex justify-center text-yellow-300'>{language == 'en' ?"Free for 24 hours then 11AED/Week":"مجانًا لمدة 24 ساعة ثم 11 درهمًا إماراتيًا/أسبوعًا"}</span> */}
//         </div>

//         <div className=' flex justify-center'>

//           <a class="block max-w-3xl p-6  bg-transparent   dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

//             <h5 class=" text-center mt-[-10px] md:text-xl font-semibold tracking-tight text-white dark:text-white">{language == 'en' ? "Terms & Conditions :" : "الشروط والأحكام:"}</h5>
//             <p class=" md:text-lg text-white font-semibold dark:text-gray-400 pb-3">{language == 'en' ? "By Clicking on Subscribe, you agree to the below terms and conditions:" : 'بالضغط على زر اشترك أعلاه، فأنت توافق على الشروط والأحكام التالية:'} </p>

//             {language == 'en' ? <ul class=" text-white text-sm list-disc  list-inside">
//               <li>
//                 You will start the paid subscription after the free period automatically.

//               </li>
//               <li>
//                 No commitment, you can cancel your subscription at any time by sending C GVS to 1111

//               </li>
//               <li>
//                 To get support, please contact support@kncee.com.

//               </li>
//               <li>
//                 The free trial is valid only for new subscribers.

//               </li>
//               <li>
//                 Enjoy your Free trial for 24 hours.

//               </li>
//               <li>
//                 Please make sure that your browser is not using any 3rd-party blocking technologies and you have a healthy internet connection for swift access to the content.

//               </li>
//               <li>
//                 <Link className=' ' to='/terms&conditions'> Further Terms and conditions click here {`>`} Privacy Policy
//                 </Link>
//               </li>
//               <li>  By proceeding, you are accepting all Terms and Conditions of the service and agree to receive updates about your subscription on your registered mobile number.

//               </li>

//             </ul> :
//               <div className='flex flex-col self-end'>
//                 <ul class=" text-white  text-sm list-disc  list-inside">
//                   <li>
//                     سوف يبدأ الاشتراك المدفوع تلقائياً بعد فترة التجربة المجانية

//                   </li>
//                   <li>
//                     لا يوجد التزام، يمكنك إلغاء اشتراكك في أي وقت عن طريق إرسال C GVS إلى 1111

//                   </li>
//                   <li>
//                     للحصول على الدعم، يرجى الاتصال بي على support@kncee.com

//                   </li>
//                   <li>
//                     التجربة المجانية صالحة فقط للمشتركين لأول مرة
//                   </li>
//                   <li>
//                     استمتع بفترة التجربة المجانية لمدة 24 ساعة
//                   </li>
//                   <li>
//                     يرجى التأكد من عدم استخدام أي تقنيات حجب من الطرف الثالث على متصفحك والتأكد من وجود اتصال إنترنت جيد لضمان الوصول السريع للمحتوى.
//                   </li>
//                   <li>
//                     <Link to="/terms&conditions">

//                       للشروط والأحكام الإضافية برجاء الضغط هنا وسياسة الخصوصية برجاء الضغط هنا

//                     </Link>
//                   </li>

//                   <li>

//                     بالمتابعة، أنت توافق على جميع الشروط والأحكام الخاصة بالخدمة وتوافق على استلام تحديثات حول اشتراكك على رقم الجوال المسجل.


//                   </li>

//                 </ul>
//               </div>
//             }


//           </a>

//         </div>

//       </div>

//       <ToastContainer />



//     </div>
//   )
// }

// export default Subscription


import React, { useState } from 'react';
import bg2 from '../assets/bg2.jpg';
import './Subscription.css';
import logo from '../assets/logo_gameit.png';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Subscription = () => {
  const url = '/subscription';
  const [number, setNumber] = useState('');
  const [language, setLanguage] = useState('en');
  const navigate = useNavigate();
  const evinaRequestId = uuidv4();
  const hostname = window.location.hostname;

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const config = {
        headers: {
          'X-Hostname': hostname
        }
      };

      const response = await axios.post(url, { msisdn: number, requestId: evinaRequestId, lang: language }, config);
      const key = Object.keys(response.data.Response);
      const firstValue = response.data.Response[key[0]];

      if (key[0] === '3') {
        toast.error(firstValue, { position: toast.POSITION.TOP_RIGHT });
      }

      if (key[0] === '100') {
        toast.success(firstValue, { position: toast.POSITION.TOP_RIGHT });
        navigate('/otp-validation', { state: { number, evinaRequestId } });
      } else {
        navigate('/');
        setNumber('');
      }
    } catch (error) {
      console.error('Error making post request:', error);
    }
  };

  const handleClick = () => {
    window.location.href = "https://www.google.com/";
  };

  return (
    <div className="bg h-[1200px] md:h-full" style={{ backgroundImage: `url(${bg2})`, height: '1200px' }}>
      <div>
        <nav className="bg-transparent border-gray-200 dark:bg-gray-900">
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-1">
            <a className="flex items-center space-x-3 rtl:space-x-reverse">
              <img src={logo} className="h-10 w-30" alt="Flowbite Logo" />
            </a>
            <div>
              <button type="button" className="text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900" onClick={() => setLanguage('en')}>EN</button>
              <button type="button" className="text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900" onClick={() => setLanguage('ar')}>AR</button>
            </div>
          </div>
        </nav>
      </div>
<div className='text-center flex container justify-center'>
<p className={`p-1 md:p-0 text-center text-white font-bold ${language === 'ar' ? 'rtl' : ''}`}>
  {language === 'en' ? "Free for 24 hours then AED 11/WEEKLY (VAT Inclusive)" : 'مجانًا لمدة 24 ساعة ! ثم ستُفرض على الخدمة 11 درهمًا إماراتيًا / أسبوعيًا شاملاً ضريبة القيمة المضافة.'}
</p>
</div>
     


      <div className="md:py-[2px] py-[20px] px-5 md:px-0">
        <form onSubmit={handleSubmit} className={`max-w-md h-[350px] md:h-[280px] mx-auto bg-transparent shadow-lg shadow-yellow-200 border p-3 rounded-lg ${language === 'ar' ? 'rtl' : ''}`}>
          <h1 className="text-white font-bold text-xl md:text-3xl text-center">{language === 'en' ? "Gamevista" : "جيمفيستا"}</h1>
          <p className="text-yellow-300 font-bold text-center">{language === 'en' ? 'Exciting games for endless entertainment and fun !!' : "ألعاب مثيرة للترفيه والمتعة بلا حدود !!"}</p>
          <div className="mb-3">
            <label htmlFor="number" className="block mb-2 text-center md:text-md text-sm font-bold text-gray-200 dark:text-white mt-2">
              {language === 'en' ? "Enter your Etisalat Mobile Number to receive OTP" : "أدخل رقم هاتفك المحمول من اتصالات للحصول على رمز OTP"}
            </label>
            <input
              type="number"
              id="number"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-1.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 focus:outline-none"
              placeholder={language === 'en' ? "Your number here" : "رقمك هنا"}
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              required
            />
          </div>
          <div className="mx-auto flex flex-row gap-3 justify-center">
            <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 rounded-lg text-sm font-medium sm:w-auto px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700">
              {language === 'en' ? "Subscribe" : "اشترك"}
            </button>
            <button type="button" onClick={handleClick} className="text-white bg-red-700 hover:bg-red-800 rounded-lg text-sm font-medium sm:w-auto px-5 py-2 text-center dark:bg-red-600 dark:hover:bg-red-700">
              {language === 'en' ? "Exit" : "خروج"}
            </button>
          </div>

          <p className="text-sm mt-1 text-white text-center">
            {language === 'en' ? "Free for 24 hours then AED 11/WEEKLY (VAT Inclusive)" : "مجانًا لمدة 24 ساعة ! ثم ستُفرض على الخدمة 11 درهمًا إماراتياً / أسبوعيًا شاملاً ضريبة القيمة المضافة."}
          </p>
          <p className="text-sm mt-1 text-white text-center">
            {language === 'en' ? "After clicking 'Subscribe' you will receive a PIN message to confirm your subscription." : "بالضغط على 'اشترك'، سوف تتلقى رسالة تحتوي على رمز PIN لتأكيد اشتراكك."}
          </p>
        </form>

        <div className="flex justify-center mt-3">
          <div className={`block max-w-3xl p-6 bg-transparent dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 ${language === 'ar' ? 'rtl' : ''}`}>
            <h5 className="text-center mt-[-10px] md:text-xl font-semibold tracking-tight text-white dark:text-white">
              {language === 'en' ? "Terms & Conditions :" : "الشروط والأحكام:"}
            </h5>
            <p className="md:text-lg text-white font-semibold dark:text-gray-400 pb-3">
              {language === 'en' ? "By Clicking on Subscribe, you agree to the below terms and conditions:" : "بالضغط على زر اشترك أعلاه، فأنت توافق على الشروط والأحكام التالية:"}
            </p>
            <ul className="text-white text-sm list-disc list-inside">
              {language === 'en' ? (
                <>
                  <li>You will start the paid subscription after the free period automatically.</li>
                  <li>No commitment, you can cancel your subscription at any time by sending C GVS to 1111.</li>
                  <li>To get support, please contact support@kncee.com.</li>
                  <li>The free trial is valid only for new subscribers.</li>
                  <li>Enjoy your Free trial for 24 hours.</li>
                  <li>Please make sure that your browser is not using any 3rd-party blocking technologies and you have a healthy internet connection for swift access to the content.</li>
                  <li> <p>
        Further Terms and conditions click here {'>'}
        <Link to='/terms&conditions' className="text-blue-100 underline font-bold ml-2">Terms & Conditions</Link> 
          {` `}and for Privacy Policy click here {`>`}
        <Link to='/privacy&policy' className="text-blue-100 underline font-bold ml-2">Privacy & Policy</Link>
      </p></li>
                  <li>By proceeding, you are accepting all Terms and Conditions of the service and agree to receive updates about your subscription on your registered mobile number.</li>
                </>
              ) : (
                <>
                  <li>سوف يبدأ الاشتراك المدفوع تلقائياً بعد فترة التجربة المجانية.</li>
                  <li>لا يوجد التزام، يمكنك إلغاء اشتراكك في أي وقت عن طريق إرسال C GVS إلى 1111.</li>
                  <li>للحصول على الدعم، يرجى الاتصال على support@kncee.com.</li>
                  <li>التجربة المجانية صالحة فقط للمشتركين لأول مرة.</li>
                  <li>استمتع بفترة التجربة المجانية لمدة 24 ساعة.</li>
                  <li>يرجى التأكد من عدم استخدام أي تقنيات حجب من الطرف الثالث على متصفحك والتأكد من وجود اتصال إنترنت جيد لضمان الوصول السريع للمحتوى.</li>
                  <li><Link to="/terms&conditions">للشروط والأحكام الإضافية برجاء الضغط هنا وسياسة الخصوصية برجاء الضغط هنا.</Link></li>
                  <li>بالمتابعة، أنت توافق على جميع الشروط والأحكام الخاصة بالخدمة وتوافق على استلام تحديثات حول اشتراكك على رقم الجوال المسجل.</li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Subscription;
