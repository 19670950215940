// import React , {useState,useEffect} from 'react'
// import logo from '../assets/logo_gameit.png'
// import bg2 from '../assets/bg2.jpg'
// import './Subscription.css';
// import OtpInput from 'react-otp-input';

// const Otp = () => {
  
//   const [language,setLangauge]=useState('en')


//   return (
//     <div className="bg" style={{ backgroundImage: `url(${bg2})` }}>
//       <div>
    
//       <nav className="bg-transparent border-gray-200 dark:bg-gray-900">
//           <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
//             <a className="flex items-center space-x-3 rtl:space-x-reverse">
//               <img src={logo} className="h-15 w-20" alt="Flowbite Logo" />
//             </a>

//             <div className="" id="navbar-default">
//               <button type="button" class="text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900 " onClick={()=>setLangauge('en')}>EN</button>

//               <button type="button" className="text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900 " onClick={()=>setLangauge('ar')} >AR</button>

//             </div>
//           </div>
//         </nav>
//           </div>

      
// <div className='flex justify-center mt-10 md:mt-0'>
// <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
   
// <h5 class=" text-center mt-[-10px] text-xl font-bold tracking-tight text-gray-800 dark:text-white">{language=='en'?"Privacy & Policy":"سياسة الخصوصية"}</h5>
//           <p class="font-medium text-lg text-gray-800 dark:text-gray-400">{language=='en'?"By Subscribing to this Service, you have agreed to the following terms and conditions.":'من خلال النقر على زر الاشتراك أعلاه ، سوف توافق على الشروط والأحكام التالية'} </p>

//           {language=='en'?      <ul class=" text-gray-700 text-lg md:text-sm   list-disc  list-inside">
//   <li>
//   Free for 24 hours then, you will be charged 11 AED/week automatically. 
//   </li>
//   <li>
//   No commitment, you can cancel any time by sending C GVS to 1111.
//   </li>
//   <li>
//   For support, please contact: support@kncee.com
//   </li>
//   <li>
//   Free trial applicable only for first time subscriber. 
//   </li>
//   <li>
//   Enjoy your Free trial for 24 hours.
//   </li>
//   <li>
//   Please make sure that your browser is not using any 3rd-party blocking technologies and you have a healthy internet connection for swift access to the content.
//   </li>
//   {/* <p className='mt-2 text-gray-800 text-center'>For complete T&C click here. Privacy Policy</p> */}
// </ul>:
// <div className='flex flex-col self-end'>
// <ul class=" text-gray-800  text-sm list-disc  list-inside">
//   <li>
//   مجانًا لمدة 24 ساعة بعد ذلك ، سيتم تحصيل 11 درهمًا إماراتيًا / أسبوعياً تلقائيًا.
//   </li>
//   <li>
//   لا يوجد التزام ، يمكنك الإلغاء في أي وقت بإرسالC GVS إلى 1111.
//   </li>
//   <li>
//   للحصول على الدعم ، يرجى الاتصال  support@kncee.com.
//   </li>
//   <li>
//   الفترة التجريبية المجانية تنطبق فقط على المشتركين لأول مرة.
//   </li>
//   <li>
//   استمتع بالفترة التجريبية المجانية لمدة 24 ساعة..
//   </li>
//   <li>
//   يرجى التأكد من أن متصفحك لا يستخدم أي تقنيات حظر تابعة لخدمات الطرف الثالث وأن لديك اتصال إنترنت صحي للوصول السريع إلى المحتوى 
//   </li>
//   {/* <p className='mt-2 text-white text-center'>للحصول على الشروط والأحكام الكاملة انقر هنا. سياسة الخصوصية</p> */}
// </ul>
// </div>
// }
// </div>
// </div>


  
//     </div>
//   )
// }

// export default Otp



import React ,{useEffect} from 'react'

const Terms = () => {  
  
  useEffect(() => {
  // Scroll to the top when the component mounts
  window.scrollTo(0, 0);
}, []);
  return (
    <div className=' container mx-auto border flex-col justify-center'>
      <div className='container  text-center mt-5 underline'>
        <p className='font-bold text-3xl'>Terms and Conditions</p>
      </div>
<div className='flex px-2 flex-col py-5'>
  <p className='font-bold  text-xl  py-3'>1.Gamevista Content Service</p>
  <p className='px-2 text-justify'>These terms (including, but not limited to, information on how to use the service) apply to the service as published in relation to the service.
By subscribing to Gamevista, you acknowledge that you have read, understood, and agree to be bound by these terms.
If you do not accept these terms, you must not subscribe/register or use Gamevista.
</p>
</div>

<div className='flex px-2 flex-col py-5'>
  <p className='font-bold  text-xl  py-3'>2.Gamevista Operation</p>
  <p className='px-2 text-justify'>The Gamevista service will function as follows: After following the instructions and entering your mobile number on the website, you will receive a PIN code. Once you enter this secret code, you are subscribed to the service. You will receive a welcome message containing all relevant information about the service (price, number of messages, etc.) and a service description. As part of your subscription, depending on the service you join, you will also receive SMS messages containing mobile content ("subscription messages").
</p>
</div>

<div className='flex px-2 flex-col py-5'>
  <p className='font-bold  text-xl  py-3'>3.Gamevista Status and Costs</p>
  <p className='px-2 text-justify'>The subscription starts at 11AED/WEEKLY and will automatically renew daily. There is no commitment. You can cancel at any time by sending C GVS to 1111. For support, please contact support@kncee.com.
</p>
</div>
<div className='flex px-2 flex-col py-5'>
  <p className='font-bold  text-xl  py-3'>4.Subscriber Definition</p>
  <p className='px-2 text-justify'>A subscriber is a person (you or any individual using your mobile phone) who follows the registration steps directed on the website.
By subscribing to our services, you agree to receive one subscription message per week containing any mobile content related to the service. The content of these messages is for personal use only.

</p>
</div>
<div className='flex px-2 flex-col py-5'>
  <p className='font-bold  text-xl  py-3'>5.Usage Restrictions</p>
  <p className='px-2 text-justify'>To use the service, you must be a resident of United Arab Emirates, at least 18 years old, or an immediate family member. If you are under 18, you must obtain explicit permission from an adult or the mobile phone owner.
The SMS subscription mechanism is only open to consumers with a mobile phone compatible with SMS messaging connected to a service provider that allows texting to the phone number we advertise for the service. We recommend checking with your individual service provider in this regard.


</p>
</div>
<div className='flex px-2 flex-col py-5'>
  <p className='font-bold  text-xl  py-3'>6.Subscription Validation</p>
  <p className='px-2 text-justify'>We reserve the right to validate the subscription or subscriber and disqualify any subscription or subscriber that tampers with the service or does not use the service according to these terms.


</p>
</div>

<div className='flex px-2 flex-col py-5'>
  <p className='font-bold  text-xl  py-3'>7.Unsubscription</p>
  <p className='px-2 text-justify'>To voluntarily unsubscribe or withdraw from our services, the end user must send the word C UNSUB KEYWORD to 1111. We reserve the right to suspend or prevent anyone from subscribing to the service.


</p>
</div>
<div className='flex px-2 flex-col py-5'>
  <p className='font-bold  text-xl  py-3'>8.Service Interruption</p>
  <p className='px-2 text-justify'>If the service is unable to operate as planned for any reason (including, but not limited to, computer viruses, errors, tampering, unauthorized intervention, fraud, technical failures, or any other causes) that could compromise the integrity or proper functioning of the service, we reserve the right to cancel, terminate, modify, or suspend the service.
</p>
</div>
<div className='flex px-2 flex-col py-5'>
  <p className='font-bold  text-xl  py-3'>9.Service Suspension or Termination</p>
  <p className='px-2 text-justify'>If, for any reason, our services are unable to operate as planned (including, but not limited to, technical failures, unauthorized interference, fraud, or any other causes beyond our control that compromise the management, security, fairness, or proper conduct of the service), we have the right, at our sole discretion, to exclude any individual who tampers with the service, cancel, terminate, modify, or suspend the service.
</p>
</div>

<div className='flex px-2 flex-col py-5'>
  <p className='font-bold  text-xl  py-3'>10.Liability</p>
  <p className='px-2 text-justify'>We are not responsible for any failure to comply with these terms. If any part or provision of these terms is found to be invalid, unenforceable, or illegal for any reason, that part or provision will be severed, and the remaining provisions will remain in full force and effect.
</p>
</div>

<div className='flex px-2 flex-col py-5'>
  <p className='font-bold  text-xl  py-3'>11.Entire Agreement</p>
  <p className='px-2 text-justify'>These terms and conditions constitute the entire agreement between you and the owner of our services.
By subscribing to the service, you agree to all terms and conditions and authorize Etisalat to share your mobile number with the service providers. 

</p>
</div>




    </div>
  )
}

export default Terms
